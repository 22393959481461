<div class="matero-page-header">
  <div class="matero-page-header-inner">
    <div class="esign-header-section">
      <div class="esign-header-headline">
        <h1>OPTiSIGN</h1>
        <h2>{{"guest.subtitle" | translate}}</h2>
      </div>
      <div class="esign-header-start">
        <a (click)="handleLoginClick($event)" mat-raised-button class="guest-login">{{"guest.btn.login" | translate}}</a>
      </div>
    </div>
  </div>
</div>

<div class="esign-homepage-promo">
  <div class="esign-homepage-row">
    <div class="esign-homepage-promo-img">
      <lib-esign-common-svg-viewer scaleToContainer="true" src="./assets/images/homepage/osobiste_certyfikaty.svg">
      </lib-esign-common-svg-viewer>
    </div>
    <div class="esign-homepage-promo-desc">
      <h2>{{"guest.personal-certificates" | translate}}</h2>
      <p>{{"guest.personal-certificates.desc" | translate}}
      </p>
    </div>
  </div>
  <div class="esign-homepage-row esign-homepage-reverse-row">
    <div class="esign-homepage-promo-img">
      <lib-esign-common-svg-viewer scaleToContainer="true" src="./assets/images/homepage/podpis_elektroniczny.svg">
      </lib-esign-common-svg-viewer>
    </div>
    <div class="esign-homepage-promo-desc">
      <h2>{{"guest.electronic-signature" | translate}}</h2>
      <p>{{"guest.electronic-signature.desc" | translate}}</p>
    </div>
  </div>
  <div class="esign-homepage-row">
    <div class="esign-homepage-promo-img">
      <lib-esign-common-svg-viewer scaleToContainer="true" src="./assets/images/homepage/weryfikacja_podpisu.svg">
      </lib-esign-common-svg-viewer>
    </div>
    <div class="esign-homepage-promo-desc">
      <h2>{{"guest.signature-verification" | translate}}</h2>
      <p>{{"guest.signature-verification.desc" | translate}}</p>
    </div>
  </div>
  <div class="esign-homepage-row esign-homepage-reverse-row">
    <div class="esign-homepage-promo-img">
      <lib-esign-common-svg-viewer scaleToContainer="true" src="./assets/images/homepage/dziennik_zdarzen.svg">
      </lib-esign-common-svg-viewer>
    </div>
    <div class="esign-homepage-promo-desc">
      <h2>{{"guest.event-log" | translate}}</h2>
      <p>{{"guest.event-log.desc" | translate}}</p>
    </div>
  </div>
  <div class="esign-homepage-bottom-start">
    <a (click)="handleLoginClick($event)" color="primary" mat-raised-button>{{"guest.btn.login" | translate}}</a>
  </div>
</div>
<app-esign-footer></app-esign-footer>
