import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private pageTitle = '';

  get title(): string {
    return this.pageTitle;
  }

  set title(title: string) {
    this.pageTitle = title;
    if (title !== '') {
      title = `${title} | `;
    }
    this.bodyTitle.setTitle(`${title}OPTiSIGN`);
  }

  constructor(private bodyTitle: Title) {}
}
